import React from 'react';

import ImprovedEndoPainImage from '../../assets/images/improved-endo-pain.svg';

export function ImprovedEndoPain() {
  return (
    <section className="bg-purple-100 improved-endo-container">
      <div className="container mx-auto">
        <div className="flex flex-col xs:flex-row items-center justify-center lg:justify-between improved-endo-content mx-auto">
          <img
            src={ImprovedEndoPainImage}
            height={73}
            width={90}
            alt="Period cycle icon"
            loading="lazy"
          />

          <div className="flex flex-col items-start improved-text">
            <h2 className="font-semibold text-white-100 mx-auto xs:ms-0 text-center xs:text-left">
              Improved endo pain within 2 period cycles
            </h2>

            <p className="text-white-100 text-center xs:text-left">
              While the studies were not specifically designed to determine how
              quickly Myfembree worked, some women started seeing an improvement
              in period pain at 2 months.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
