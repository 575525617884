import React from 'react';

import DuringPeriodsIcon from '../../assets/images/during-periods-icon.svg';
import BetweenPeriodsIcon from '../../assets/images/between-periods-icon.svg';
import DuringSexIcon from '../../assets/images/during-sex-icon.svg';

import useIsEdge from '../../util/use-is-edge';

const searchingList = [
  {
    img: DuringPeriodsIcon,
    imgW: 98,
    imgH: 77,
    text: 'during periods',
    alt: ''
  },
  {
    img: BetweenPeriodsIcon,
    imgW: 85,
    imgH: 88,
    text: 'between periods',
    alt: ''
  },
  {
    img: DuringSexIcon,
    imgW: 85,
    imgH: 85,
    text: 'during sex*',
    alt: ''
  }
];

function WhyMyFembreeSearching() {
  const isEdge = useIsEdge();

  return (
    <section>
      <div className={`container mx-auto ${isEdge ? 'is-edge-container' : ''}`}>
        <div className="searching-card flex flex-col justify-center items-center text-white-100">
          <h2 className="font-mulish font-bold text-center">
            Searching for <br className="md:hidden" /> relief from your
            endometriosis pain?
          </h2>

          <p className="text-center font-semibold">
            Myfembree is proven to relieve 3 types of endometriosis pain.
          </p>

          <ul className="flex flex-wrap items-end justify-between">
            {searchingList.map((search, i) => {
              return (
                <li className="flex flex-col items-center " key={i}>
                  <img
                    src={search.img}
                    width={search.imgW}
                    height={search.imgH}
                    alt={search.alt}
                  />

                  <span className="font-semibold">{search.text}</span>
                </li>
              );
            })}
          </ul>

          <small className="text-center block">
            *Based on those women who were engaging in sex at the start of and
            during clinical trials.
          </small>

          <p className="text-center">
            Myfembree is not a surgery, procedure, injection, or painkiller. It
            is a once-daily prescription pill designed to manage moderate to
            severe endometriosis pain.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhyMyFembreeSearching;
