import React from 'react';

import EndometriosisImage from '../../assets/images/endometriosis-image.png';
import EndometriosisImageWebp from '../../assets/images/endometriosis-image.png';

function WhatCauses() {
  return (
    <section className="what-causes-endometriosis-pain">
      <div className="container mx-auto">
        <div className="relative what-causes-endometriosis-pain-content mx-auto">
          <div className="">
            <h2 className="font-mulish font-bold text-purple-100 text-center lg:text-left ">
              What causes endometriosis pain?
            </h2>

            <picture className="relative lg:absolute end-0 block mx-auto">
              <source srcSet={EndometriosisImageWebp} type="image/webp" />

              <img
                className="w-100 img-fluid"
                src={EndometriosisImage}
                alt="Illustration of uterus with endometriosis"
                width={356}
                height={300}
                loading="lazy"
              />
            </picture>
          </div>

          <p className="text-grey-100 text-center lg:text-left mx-auto lg:ms-0">
            When you have endometriosis, the pain you experience is caused by
            the tissue similar to what lines the uterus growing outside of the
            uterus, leading to lesions and inflammation.
          </p>

          <p className="text-grey-100 text-center lg:text-left mx-auto lg:ms-0">
            A hormone called estrogen contributes to the growth of these
            lesions. As your body passes through its natural menstrual cycle,
            changing levels of estrogen cause lesions to grow. That’s why you
            might feel endometriosis symptoms, including pain during your
            period, between periods, and even during sex.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhatCauses;
