import React from 'react';

import image1 from '../../assets/images/why-myfembree-hero-image-1.jpg';
import image1_s from '../../assets/images/why-myfembree-hero-image-1_s.jpg';
import image1webp from '../../assets/images/why-myfembree-hero-image-1.webp';
import image1webp_s from '../../assets/images/why-myfembree-hero-image-1_s.webp';
import image2 from '../../assets/images/why-myfembree-hero-image-2.jpg';
import image2_s from '../../assets/images/why-myfembree-hero-image-2_s.jpg';
import image2webp from '../../assets/images/why-myfembree-hero-image-2.webp';
import image2webp_s from '../../assets/images/why-myfembree-hero-image-2_s.webp';

function WhyMyfembreeHero() {
  return (
    <section className="relative why-myfembree-hero">
      <div className="container mx-auto">
        <div className="text-white-100 flex flex-col md:flex-row justify-between items-center md:items-baseline">
          <h1 className="text-center">
            Endometriosis pain will always be a part of my life.
          </h1>

          <span className="text-center">
            Days like this should be a bigger part.
          </span>

          <div className="absolute left-0 top-0 flex flex-col md:flex-row endo-hero-image">
            <picture className="">
              <source
                srcSet={image1webp_s}
                media="(max-width: 896px)"
                type="image/webp"
              />

              <source srcSet={image1_s} media="(max-width: 896px)" />

              <source
                srcSet={image1webp}
                type="image/webp"
                media="(min-width: 896px)"
              />

              <source srcSet={image1} media="(min-width: 896px)" />

              <img
                className="w-100 img-fluid"
                src={image1_s}
                alt="Woman experiencing endometriosis pain. Not an actual patient."
                width={720}
                height={626}
              />
            </picture>

            <picture className="">
              <source
                srcSet={image2webp_s}
                media="(max-width: 896px)"
                type="image/webp"
              />

              <source srcSet={image2_s} media="(max-width: 896px)" />

              <source
                srcSet={image2webp}
                type="image/webp"
                media="(min-width: 896px)"
              />

              <source srcSet={image2} media="(min-width: 896px)" />

              <img
                className="w-100 img-fluid"
                src={image2_s}
                alt="Woman living with endometriosis pain who is taking MYFEMBREE® (relugolix, estradiol, and norethindrone acetate). Not an actual patient."
                width={720}
                height={626}
                fetchpriority="high"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyMyfembreeHero;
