import React from 'react';

import EndometriosisPill from '../../assets/images/how-does-mf-work-pill.png';
import EndometriosisPillWebp from '../../assets/images/how-does-mf-work-pill.webp';

function HowDoesMyfembreeWork() {
  return (
    <section className="how-does-mf-work-container" id="how-does-it-work">
      <div className="container mx-auto">
        <div className="how-dows-mf-work-card flex flex-col justify-center items-center text-white-100 mx-auto">
          <h2 className="font-mulish font-bold text-center lg:text-left lg:me-auto text-purple-100">
            How does Myfembree work to treat endometriosis pain?
          </h2>

          <p className="text-center lg:text-left lg:self-start">
            Myfembree is a once-daily medication specifically designed to
            decrease moderate to severe endo pain throughout your cycle.
            Myfembree contains three key ingredients, designed to support an
            optimal hormone range that may help reduce endo pain.
          </p>

          <ul className="flex flex-nowrap flex-col lg:flex-row lg:flex-wrap items-start justify-between">
            <li className="self-center grid">
              <div>
                <h3 className="font-semibold text-center lg:text-end">
                  Relugolix:
                </h3>

                <p className="text-center lg:text-end">
                  Reduces hormones, such as <br className="hidden md:block" />{' '}
                  estrogen, which reduces endo pain.
                </p>
              </div>
            </li>

            <li className="mx-auto">
              <picture className="">
                <source srcSet={EndometriosisPillWebp} type="image/webp" />

                <source srcSet={EndometriosisPill} />

                <img
                  className="w-100 img-fluid"
                  src={EndometriosisPillWebp}
                  alt="MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) pill for endometriosis pain. Not actual pill size."
                  width={132}
                  height={119}
                  loading="lazy"
                />
              </picture>
            </li>

            <li className="self-center grid">
              <div>
                <h3 className="font-semibold text-center lg:text-start">
                  Norethindrone acetate:
                </h3>

                <p className="text-center lg:text-start">
                  May protect the uterus from the effect of estrogen alone.
                </p>
              </div>
            </li>

            <li className="grid justify-items-center mx-auto">
              <h3 className="font-semibold text-center lg:text-end">
                Estradiol (an estrogen):
              </h3>

              <p className="text-center lg:text-center mx-auto">
                May reduce bone loss from relugolix alone.
              </p>
            </li>
          </ul>

          <small className="block text-grey-100">
            Not the actual pill size
          </small>
        </div>
      </div>
    </section>
  );
}

export default HowDoesMyfembreeWork;
