import React from 'react';
import { Link } from 'gatsby';

export function Ready() {
  return (
    <section className="bg-purple-100 ready-container">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center ready-container-content">
          <h2 className="font-semibold text-white-100">
            Ready to have an unfiltered conversation with your doctor about
            Myfembree?
          </h2>

          <Link className="white-btn" to="/endometriosis/at-the-doctor/">
            START THE CONVERSATION
          </Link>
        </div>
      </div>
    </section>
  );
}

export function FindOut() {
  return (
    <section className="find-out-container">
      <div className="container mx-auto">
        <div className="flex items-center flex-wrap find-out-container-content">
          <h2 className="font-semibold text-white-100">
            Find out how you can save on your Myfembree prescription.
          </h2>

          <Link className="white-btn" to="/endometriosis/cost-and-support/">
            MYFEMBREE COST SAVINGS
          </Link>
        </div>
      </div>
    </section>
  );
}
