import React from 'react';

import BenefitsBg from '../../assets/images/benefits-bg.jpg';
import BenefitsBgWebp from '../../assets/images/benefits-bg.webp';
import BenefitsBg_s from '../../assets/images/benefits-bg-s.jpg';
import BenefitsBgWebp_s from '../../assets/images/benefits-bg-s.webp';
import BenefitsBg_xs from '../../assets/images/benefits-bg-xs.jpg';
import BenefitsBgWebp_xs from '../../assets/images/benefits-bg-xs.webp';
import BenefitsBg_xs2x from '../../assets/images/benefits-bg-xs@2x.jpg';
import BenefitsBgWebp_xs2x from '../../assets/images/benefits-bg-xs@2x.webp';

import BenefitsList from './benefits-list';

const list = [
  {
    percentage: (
      <>
        <span>74</span>
        <span>%</span>
        <span>less pain</span>
      </>
    ),
    subtext: (
      <p className="font-bold text-green-100 text-center subtext">
        during periods<sup className="why-myfembree-sup">‡</sup>
      </p>
    ),
    notes: [
      <span className="text-center block">
        <sup>‡</sup>At week 24, compared with a 26% reduction on placebo.
      </span>
    ]
  },
  {
    percentage: (
      <>
        <span>50</span>
        <span>%</span>
        <span>less pain</span>
      </>
    ),
    subtext: (
      <p className="font-bold text-green-100 text-center subtext">
        between periods<sup className="why-myfembree-sup">§</sup>
      </p>
    ),
    notes: [
      <span className="text-center block">
        <sup>§</sup>At week 24, compared with a 36% reduction on placebo.
      </span>
    ]
  },
  {
    percentage: (
      <>
        <span>43</span>
        <span>%</span>
        <span>less pain</span>
      </>
    ),
    subtext: (
      <p className="font-bold text-green-100 text-center subtext">
        during sex<sup className="why-myfembree-sup">‖¶</sup>
      </p>
    ),
    notes: [
      <span className="text-center block">
        <sup>‖</sup>At week 24, compared with a 29% reduction on placebo.
      </span>,
      <span className="text-center block">
        <sup>¶</sup>Based on those women who were engaging in sex at the start
        of and during clinical trials.
      </span>
    ]
  }
];

function Benefits() {
  return (
    <>
      <section className=" relative">
        <div className="benefits-container relative">
          <div className="flex justify-end benefits-container-main">
            <div className="benefits-container-content">
              <div className="clinical-studies">
                <h2 className="font-mulish text-purple-100 font-bold text-center lg:text-left">
                  What are the benefits of Myfembree?
                </h2>

                <p className="text-grey-100 text-center lg:text-left">
                  In clinical studies, Myfembree was proven effective in
                  providing meaningful endometriosis pain relief.
                </p>

                <p className="text-grey-100 text-center lg:text-left">
                  The two 6-month clinical studies involved 1,251 premenopausal
                  women with moderate to severe endometriosis pain.
                </p>
              </div>

              <div className="after-taking-container bg-purple-100">
                <h3 className="text-white-100 text-center md:text-left font-mulish font-bold">
                  After taking Myfembree:
                </h3>

                <ul className="flex flex-col md:flex-row md:justify-between relative text-white-100 mx-auto md:ms-0">
                  <li className="flex flex-col">
                    <span className="font-bold text-center flex justify-center">
                      <span>7</span>
                      <span>out of</span>
                      <span>10</span>
                    </span>

                    <span className="text-center font-semibold mx-auto">
                      women felt <br className="xs:hidden md:block" /> less
                      period pain*
                    </span>

                    <small className="text-center block mx-auto">
                      *At week 24, compared with{' '}
                      <br className="xs:hidden lg:block" /> 3 out of 10 women on
                      placebo.
                    </small>
                  </li>

                  <li className="flex flex-col">
                    <span className="font-bold text-center flex justify-center">
                      <span>6</span>
                      <span>out of</span>
                      <span>10</span>
                    </span>

                    <span className="text-center font-semibold mx-auto">
                      women felt less <br className="xs:hidden md:block" /> pain
                      between periods<sup className="why-myfembree-sup">†</sup>
                    </span>

                    <small className="text-center block mx-auto">
                      <sup>†</sup>At week 24, compared with{' '}
                      <br className="xs:hidden lg:block" /> 4 out of 10 women on
                      placebo.
                    </small>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <picture className="absolute top-0">
            <source
              srcSet={`${BenefitsBgWebp_xs}, ${BenefitsBgWebp_xs2x} 2x`}
              media="(max-width: 672px)"
              type="image/webp"
            />

            <source
              srcSet={`${BenefitsBg_xs}, ${BenefitsBg_xs2x} 2x`}
              media="(max-width: 672px)"
            />

            <source
              srcSet={BenefitsBgWebp_s}
              media="(max-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={BenefitsBg_s} media="(max-width: 1152px)" />

            <source
              srcSet={BenefitsBgWebp}
              media="(min-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={BenefitsBg} media="(min-width: 1152px)" />

            <img
              className="w-100 img-fluid"
              src={BenefitsBgWebp_xs}
              alt="Woman who is taking MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) for her endometriosis pain taking a walk outside. Not an actual patient."
              // width={720}
              // height={626}
            />
          </picture>
        </div>
      </section>

      <section className="after-taking mx-auto">
        <div className="container mx-auto">
          <h2 className="font-mulish text-purple-100 font-bold text-center mx-auto">
            Studies also proved that Myfembree reduced 3 types of endometriosis
            pain:
          </h2>

          <ul className="flex flex-wrap justify-between">
            {list.map((item, i) => {
              return (
                <li key={i} className="">
                  <span className="text-purple-100 font-bold percentage">
                    {item.percentage}
                  </span>

                  {item.subtext}

                  <div className="notes mx-auto">
                    {item.notes.map((item, i) => {
                      return <>{item}</>;
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      {/* <section>
        <BenefitsList />
      </section> */}
    </>
  );
}

export default Benefits;
