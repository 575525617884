import React from 'react';

import CommonSideEffectsIcon from '../../assets/images/common-side-effect-icon.png';
import SeriousSideEffectsIcon from '../../assets/images/serious-side-effect-icon.png';
import DiscontinuationsSideEffectsIcon from '../../assets/images/discontinuations-icon.png';

function PossibleSideEffects() {
  return (
    <section className="possible-side-effects-container" id="side-effects">
      <div className="container mx-auto">
        <h2 className="font-mulish mx-auto">
          What are the possible side effects with Myfembree?
        </h2>

        <p className="mx-auto">
          In clinical trials, the safety of Myfembree was also studied.
        </p>

        <ul className="">
          <li className="mx-auto flex flex-col md:flex-row items-center md:items-start">
            <img
              src={CommonSideEffectsIcon}
              width={96}
              height={92}
              alt=""
              loading="lazy"
            />

            <div>
              <h3 className="font-mulish">The most common side effects </h3>

              <p>
                The most common side effects of taking Myfembree for moderate to
                severe endometriosis pain were headache; hot flushes, sweating,
                or night sweats; mood changes, including worsening depression;
                abnormal vaginal bleeding (bleeding that lasts too long, that is
                too heavy, or is unexpected); nausea; toothache; back pain;
                decreased interest in sex; joint pain; tiredness; and dizziness.
              </p>
            </div>
          </li>

          <li className="mx-auto flex flex-col md:flex-row items-center md:items-start">
            <img
              src={SeriousSideEffectsIcon}
              width={96}
              height={92}
              alt=""
              loading="lazy"
            />

            <div>
              <h3 className="font-mulish">Serious side effects </h3>

              <p>
                Serious side effects were reported in 2.9% of women on Myfembree
                and 2.2% of women on placebo. Serious side effects were uterine
                hemorrhage, suicidal ideation, gallstones, and gallbladder
                inflammation.
              </p>
            </div>
          </li>

          <li className="mx-auto flex flex-col md:flex-row items-center md:items-start">
            <img
              src={DiscontinuationsSideEffectsIcon}
              width={96}
              height={92}
              alt=""
              loading="lazy"
            />

            <div>
              <h3 className="font-mulish">Discontinuations</h3>

              <p>
                In clinical trials, 4.5% of women treated with Myfembree stopped
                taking it because of side effects, compared with 2.9% of women
                in the placebo group. The most common side effect that led to
                discontinuation for women taking Myfembree was mood-related
                disorders (1.7%).
              </p>
            </div>
          </li>
        </ul>

        <small className="block font-semibold mx-auto">
          These are not all the possible side effects of Myfembree.
        </small>
      </div>
    </section>
  );
}

export default PossibleSideEffects;
